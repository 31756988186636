import { makeStyles } from '@fluentui/react-components';
import {
    SiAdobeacrobatreader,
    SiGoogledocs,
    SiMicrosoftexcel,
    SiMicrosoftoutlook,
    SiMicrosoftpowerpoint,
    SiMicrosoftword,
} from '@icons-pack/react-simple-icons';

import { Image16Regular } from '@fluentui/react-icons';

interface IFileIconProps {
    fileNameOrURL: string;
}

const useClasses = makeStyles({
    iconFileType: {
        display: 'inline-block',
        position: 'relative',
        top: '2px',
        width: '20px',
    },
});

const iconSize = 14;
const iconColour = 'white';

export const FileIcon: React.FC<IFileIconProps> = ({ fileNameOrURL }) => {

    const classes = useClasses();
    const fileName = fileNameOrURL.toLocaleLowerCase();
    let fileType = '';
    if (fileName.includes('.docx') || fileName.includes('.doc') || fileName.includes('.docm')) {
        fileType = 'docx';
    } else if (fileName.includes('.pptx') || fileName.includes('.ppt') || fileName.includes('.pptm')) {
        fileType = 'pptx';
    } else if (
        fileName.includes('xlsx') ||
        fileName.includes('.xls') ||
        fileName.includes('.xlsm') ||
        fileName.includes('.csv')
    ) {
        fileType = 'xlsx';
    } else if (fileName.includes('.pdf')) {
        fileType = 'pdf';
    } else if (fileName.includes('.eml') || fileName.includes('.msg')) {
        fileType = 'eml';
    } else if (fileName.includes('.txt')) {
        fileType = 'txt';
    } else if (fileName.includes('.png') || fileName.includes('.jpg') || fileName.includes('.jpeg')) {
        fileType = 'image';
    }

    return (
        <div className={classes.iconFileType}>
            {fileType === 'docx' && (
                <SiMicrosoftword
                    color={iconColour}
                    size={iconSize}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            )}
            {fileType === 'pptx' && (
                <SiMicrosoftpowerpoint
                    color={iconColour}
                    size={iconSize}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            )}
            {fileType === 'xlsx' && (
                <SiMicrosoftexcel
                    color={iconColour}
                    size={iconSize}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            )}
            {fileType === 'pdf' && (
                <SiAdobeacrobatreader
                    color={iconColour}
                    size={iconSize}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            )}
            {fileType === 'eml' && (
                <SiMicrosoftoutlook
                    color={iconColour}
                    size={iconSize}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            )}
            {fileType === 'txt' && (
                <SiGoogledocs
                    color={iconColour}
                    size={iconSize}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
            )}
            {fileType === 'image' && <Image16Regular color={iconColour} fontSize={iconSize} />}
        </div>
    );

};
