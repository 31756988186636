import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Link,
    Spinner,
    Text,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components';
import { useState } from 'react';
import { useChat } from '../../../libs/hooks/useChat';
import { IChatMessageGroupedAttachment } from '../../../libs/models/ChatMessage';
import { customTokens } from '../../../styles';
import { FileIcon } from './FileIcon';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginTop: customTokens.spacingVerticalS,
        backgroundColor: customTokens.colorNeutralBackground3,
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
    },
    header: {
        '> button': {
            paddingLeft: '2px',
        },
    },
    headerText: {
        flexDirection: 'row',
        display: 'flex',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '100%',
    },
    downloadSpinner: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
    },
    panel: {
        paddingTop: customTokens.spacingVerticalXXS,
        paddingBottom: customTokens.spacingVerticalM,
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: customTokens.spacingVerticalS,
        marginBottom: customTokens.spacingVerticalS,
        flexWrap: 'wrap',
    },
    pillWrapper: {
        marginBottom: customTokens.spacingHorizontalS,
    },
    groupName: {
        ...shorthands.flex(1, 0, '100%'),
    },
    pill: {
        ...shorthands.borderRadius(customTokens.borderRadiusCircular),
        backgroundColor: customTokens.colorBrandBackground,
        lineHeight: customTokens.lineHeightBase100,
        ...shorthands.padding(customTokens.spacingVerticalXXS, customTokens.spacingHorizontalS),
        marginRight: customTokens.spacingHorizontalS,
        fontSize: customTokens.fontSizeBase200,
    },
    link: {
        color: customTokens.colorBrandBackgroundInverted,
        ':hover': {
            color: customTokens.colorBrandBackgroundInverted,
        },
    },
    highlight: {
        backgroundColor: 'rgb(66, 201, 194)',
    },
    noUrl: {
        cursor: 'default',
        textDecorationLine: 'none',
        ':hover': {
            textDecorationLine: 'none',
        },
        ':active': {
            textDecorationLine: 'none',
            color: customTokens.colorBrandBackgroundInverted,
        },
    },
});

interface IGroupedAttachmentsProps {
    attachments?: IChatMessageGroupedAttachment[];
}

export const GroupedAttachments: React.FC<IGroupedAttachmentsProps> = ({ attachments }) => {
    const classes = useClasses();
    const chat = useChat();

    // state for downloading attachments
    const [downloading, setDownloading] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState('');

    if (!attachments || attachments.length === 0) {
        return null;
    }

    const groupedAttachments = attachments.reduce((grouped: Record<string, string[]>, item) => {
        const { id, type, url } = item;

        const attachmentKey = type && id ? `${type}: ${id}` : 'Unknown';

        if (!grouped.hasOwnProperty(attachmentKey)) {
            grouped[attachmentKey] = [];
        }

        grouped[attachmentKey].push(url);

        return grouped;
    }, {});

    const downloadAttachment = async (attachmentPath: string) => {
        // get filename from path
        const fileName = attachmentPath.split('/').pop();

        // get file extension
        const fileExtension = fileName?.split('.').pop();

        // set downloading file name
        setDownloadingFile(fileName ?? '');
        setDownloading(true);

        // The attachment path is a URL, we need to remove the domain name and the first folder
        // to get the relative path to the file
        const relativePath = attachmentPath.split('/').slice(4).join('/');

        const blob = await chat.downloadAttachment(relativePath);

        if (blob instanceof Blob) {
            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element and click it to initiate download
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName ?? `attachment.${fileExtension}`;
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        }

        setDownloadingFile('');
        setDownloading(false);
    };

    return (
        <div className={classes.root}>
            <Accordion collapsible>
                <AccordionItem value="">
                    <AccordionHeader className={classes.header}>
                        <Text size={200} weight="bold" className={classes.headerText}>
                            <>Attachments*&nbsp;</>
                            {downloading && (
                                <div className={classes.downloadSpinner}>
                                    <Spinner size="extra-tiny" label={`Downloading ${downloadingFile}`} />
                                </div>
                            )}
                        </Text>
                    </AccordionHeader>
                    <AccordionPanel className={classes.panel}>
                        <Text size={200}>Here is a list of attachments related to the response above:</Text>
                        <div>
                            {Object.keys(groupedAttachments).map((key, keyIndex) => {
                                const attachmentList: JSX.Element[] = [];
                                const attachmentUrls = groupedAttachments[key];

                                attachmentUrls.forEach((attachmentUrl, attachmentUrlsIndex) => {
                                    let url;
                                    let isValidUrl = true;

                                    try {
                                        url = new URL(attachmentUrl);
                                    } catch (e) {
                                        isValidUrl = false;
                                    }

                                    if (isValidUrl && url) {
                                        const domain = url.hostname.replace('www.', '');

                                        if (
                                            domain.includes('sharepoint.com') ||
                                            domain.includes('blob.core.windows.net')
                                        ) {
                                            const documentName = url.pathname.split('/').pop();

                                            if (!documentName) {
                                                return;
                                            }

                                            const decodedDocumentName = decodeURIComponent(documentName);
                                            const documentNameSliced = decodedDocumentName.slice(0, 23);

                                            attachmentList.push(
                                                <span className={classes.pill}>
                                                    <Link
                                                        className={classes.link}
                                                        key={attachmentUrlsIndex}
                                                        title={decodedDocumentName}
                                                        target={undefined}
                                                        href={undefined}
                                                        onClick={() => {
                                                            void downloadAttachment(attachmentUrl);
                                                        }}
                                                    >
                                                        <FileIcon fileNameOrURL={attachmentUrl} />
                                                        {documentNameSliced}
                                                        ...
                                                    </Link>
                                                </span>,
                                            );
                                        } else {
                                            attachmentList.push(
                                                <span className={classes.pill}>
                                                    <Link
                                                        className={classes.link}
                                                        href={attachmentUrl}
                                                        target="_blank"
                                                        key={attachmentUrlsIndex}
                                                    >
                                                        {domain}
                                                    </Link>
                                                </span>,
                                            );
                                        }
                                    } else {
                                        const fileName = attachmentUrl;
                                        const fileNameSliced = fileName.slice(0, 23);

                                        attachmentList.push(
                                            <span className={mergeClasses(classes.pill, classes.highlight)}>
                                                <Link
                                                    className={mergeClasses(classes.link, classes.noUrl)}
                                                    key={attachmentUrlsIndex}
                                                    title={fileName}
                                                >
                                                    <FileIcon fileNameOrURL={fileName} />
                                                    {fileNameSliced}
                                                    ...
                                                </Link>
                                            </span>,
                                        );
                                    }
                                });

                                return (
                                    <div key={`key-${keyIndex}`} className={classes.linksContainer}>
                                        {key !== 'Unknown' && (
                                            <div className={mergeClasses(classes.pillWrapper, classes.groupName)}>
                                                <Text size={200} weight="bold">
                                                    {key}
                                                </Text>
                                            </div>
                                        )}
                                        {attachmentList.map((attachment, attachmentListIndex) => (
                                            <div key={attachmentListIndex} className={classes.pillWrapper}>
                                                {attachment}
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                        <Text size={200} italic>
                            * This response was generated using a combination of archive sources and Azure ChatGPT.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
};
