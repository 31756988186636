import type { DropdownProps } from '@fluentui/react-components';
import { Dropdown, makeStyles, Option, shorthands, useId } from '@fluentui/react-components';
import {
    BotSparkle24Regular,
    Cloud24Regular,
    CloudArchive24Regular,
    CloudCheckmark24Regular,
    People24Regular,
} from '@fluentui/react-icons';
import * as React from 'react';
import { useChat } from '../../../libs/hooks';
import { IAppConfigResult, useAppConfig } from '../../../libs/hooks/useAppConfig';
import { AlertType } from '../../../libs/models/AlertType';
import { IAppConfigValue } from '../../../libs/services/AppConfigService';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { addAlert } from '../../../redux/features/app/appSlice';
import { ChatScope } from '../../../redux/features/conversations/ChatState';
import { editConversationScope } from '../../../redux/features/conversations/conversationsSlice';
import { getScopeProperty, ScopeInfoDialog, ScopeOption } from '../scope-dialog/ScopeInfoDialog';

interface IEditChatScopeProps extends Partial<DropdownProps> {
    chatId: string;
    chatScope: ChatScope;
    hiddenChat: boolean;
}

const useStyles = makeStyles({
    root: {
        display: 'grid',
        justifyItems: 'start',
        ...shorthands.gap('20px'),
        maxWidth: '400px',
    },
    field: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'start',
        ...shorthands.gap('2px'),
    },
    optionLabel: {
        marginRight: '10px',
    },
});

const scopeOptionsDefault: ScopeOption = {
    [ChatScope.SharePoint]: {
        name: 'SharePoint',
        icon: () => <People24Regular />,
    },
    [ChatScope.Web]: {
        name: 'Web',
        icon: () => <Cloud24Regular />,
    },
    [ChatScope.RecommendedSites]: {
        name: 'RecommendedSites',
        icon: () => <CloudCheckmark24Regular />,
    },
    [ChatScope.None]: {
        name: 'None',
        icon: () => <BotSparkle24Regular />,
    },
    [ChatScope.Archive]: {
        name: 'Archive',
        icon: () => <CloudArchive24Regular />,
    },
};

export const EditChatScope: React.FC<IEditChatScopeProps> = (props) => {
    const [scopeOptions, setScopeOptions] = React.useState<ScopeOption>();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();
    const comboId = useId('combo-controlled');
    const styles = useStyles();

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const emailAddress = activeUserInfo?.email ?? '';
    const user = `${emailAddress}`;

    const appConfig = useAppConfig();

    const getPromptGuidanceContent = async () => {
        const response: IAppConfigResult<IAppConfigValue[]> = await appConfig.getConfigMultipleValues(
            'Scope Guidance',
            user,
        );

        if (response.Success) {
            const updatedScopes = Object.keys(scopeOptionsDefault).map((scopeKey: string) => {
                const scope = parseInt(scopeKey) as ChatScope;
                const config = response.Data?.find((d) => d.Category === scopeOptionsDefault[scope].name);
                const scopeTitle = config?.CategoryTitle ?? '';
                const scopeDescription = config?.Value ?? '';
                return { ...scopeOptionsDefault[scope], title: scopeTitle, description: scopeDescription };
            });

            setScopeOptions(updatedScopes.filter((f) => f.title));
        }
    };

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getPromptGuidanceContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOptionSelect: (typeof props)['onOptionSelect'] = (_ev, data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        if (data.optionValue) {
            onScopeChange(parseInt(data.optionValue) as ChatScope).catch((e: any) => {
                const errorMessage = `Unable to change chat scope. Details: ${
                    e instanceof Error ? e.message : String(e)
                }`;
                dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            });
        }
    };

    const onScopeChange = async (newScope: ChatScope) => {
        const chatState = conversations[selectedId];
        await chat
            .editChat(chatState.id, chatState.title, chatState.systemDescription, chatState.memoryBalance, newScope)
            .then(() => {
                localStorage.setItem('lastSelectedChatScope', newScope.toString());
                dispatch(editConversationScope({ id: props.chatId, newScope }));
            });
    };

    const getScopeOptions = () => {
        return (
            scopeOptions &&
            Object.keys(scopeOptions).map((key) => {
                const scope = parseInt(key) as ChatScope;
                // Hide RecommendedSites scope
                if (scope === ChatScope.RecommendedSites) return;
                const title = getScopeProperty(scopeOptions, scope, 'title') as string;
                return title ? (
                    <Option key={scope} value={scope.toString()}>
                        {getScopeProperty(scopeOptions, scope, 'title') as string}
                    </Option>
                ) : null;
            })
        );
    };

    return scopeOptions ? (
        <>
            <div className={styles.field}>
                <ScopeInfoDialog scopeOptions={scopeOptions} />
                <label htmlFor={`${comboId}-controlled`} className={styles.optionLabel}>
                    Choose a source:
                </label>
                <Dropdown
                    id={`${comboId}-controlled`}
                    value={getScopeProperty(scopeOptions, props.chatScope, 'title') as string}
                    selectedOptions={[props.chatScope.toString()]}
                    onOptionSelect={onOptionSelect}
                    disabled={props.hiddenChat}
                >
                    {getScopeOptions()}
                </Dropdown>
            </div>
        </>
    ) : null;
};
